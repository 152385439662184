const initBlock = async function( $block ) {
	const $section = $block;

	const scrollToNextSection = () => {
		const $scrollButton = $section.find( '.scroll-button' );
		$scrollButton.on( 'click', ( e ) => {
			e.preventDefault();
			const $wpSiteBlocks = $( '.wp-site-blocks' );
			let $nextSection = $section.nextAll( '.section, .wp-block-template-part, .wp-block-pattern' ).find( '.section:first' ).addBack( '.section:first' ).first();
			if ( $nextSection.length ) {
				const scrollTop = Math.round( $wpSiteBlocks.scrollTop() + $nextSection.position().top );
				$wpSiteBlocks.animate( {
					scrollTop: scrollTop
				}, 1000 );
			}
		} );
	};
	scrollToNextSection();

	$( '.anchor-link-js' ).on( 'click', function( event ) {
		event.preventDefault();
		let id = $( this ).attr( 'href' );
		let target = $( id );
		
		if ( target.length ) {
			let scrollContainer = $( '.wp-site-blocks' );
			let containerRect = scrollContainer.get( 0 ).getBoundingClientRect();
			let targetRect = target.get( 0 ).getBoundingClientRect();
			
			let top = targetRect.top - containerRect.top + scrollContainer.scrollTop();
			
			scrollContainer.animate( { scrollTop: top }, 1000 );
		}
	} );
	
};

themeUtils.loadBlock( initBlock, 'hero', '.section-hero' );